.header {
  background-color: #2c2c34;
  width: 83%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding-right: 20px;
  padding-left: 20px;
  position: fixed;
  right: 0;
  z-index: 1;
}

.itemHeader {
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
  font-weight: 400;
  font-family: roboto;
  border-bottom: 2px solid #5d5d69;
  color: white;
  padding-bottom: 10px;
  margin-top: 30px;
  margin-bottom: 5px;
}

.itemList {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  font-weight: 400;
  font-family: roboto;
  border-bottom: 1px solid #5d5d69;
  color: #eeeeeec2;
  padding-bottom: 10px;
  padding-top: 10px;
  align-items: center;
  padding-left: 10px;
}

.itemList:hover {
  background-color: #455a64;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: black;
  height: 1000;
  width: 1000;
}

.card {
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  background-color: white;
  height: 100%;
  width: 30%;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  font-style: inter;
}

.hero {
  height: 100vh;
  width: 70%;
  background-image: url("../assets/login.jpg");
}

.hero-container {
  height: 100%;
  width: 100%;
  background-color: #0000008f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

.input {
  width: 70%;
  height: 60px;

  border-width: 0px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 17px;
  margin-bottom: 25px;
  border-radius: 2px;
  box-shadow: 0px 0px 0px 2px rgba(209, 209, 209, 1);
  font-family: roboto;
  font-weight: 400;
}

.input:focus {
  box-shadow: 0px 0px 0px 2px #00c264;
  outline: none;
}

::placeholder {
  color: rgba(209, 209, 209, 1);
}

.input:hover {
  box-shadow: 0px 0px 0px 2px #00c264;
  outline: none;
}
.inputWithLabel {
  height: 60px;
  outline: none;
  width: 20em;
  border-width: 0px;
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #364850;
  padding: 10px;
  caret-color: #dfdfdf;
  color: #dbdadada;
  font-family: roboto;
  font-weight: 500;
  cursor: text;
}

.inputWithLabel:hover {
  box-shadow: 0px 0px 0px 2px #455a64;
}

.inputWithLabel:focus {
  box-shadow: 0px 0px 0px 2px #c5dbe6;
}

::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

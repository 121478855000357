.LogoContainer {
  width: 100%;
  height: 70px;
  padding-left: 20px;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  background-color: #263238;
}

ul {
  list-style-type: none;
  padding: 0px;
}

li:hover {
  background-color: #363641;
  cursor: pointer;
  text-decoration: none;
  color: white;
}

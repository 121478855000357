.button {
  background-color: #1e9c63;
  width: 150px;
  padding-left: 20px;
  padding-right: 20px;
  height: 60px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 17px;
  font-weight: 400;
  font-family: inter;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 0px 0px 12px 1px #2fce8613;
}

.add-menu:focus {
  outline: none;
}

.item {
  color: #f5f5f5;
  display: flex;
  padding-left: 20px;
  align-items: center;
  text-decoration: none;
  width: 90%;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 50px;
  font-weight: 400;
}

.item:hover {
  cursor: pointer;
  text-decoration: none;
  color: white;
  background-color: #455a64;
}

.logOut {
  color: #f5f5f5;
  display: flex;
  padding-left: 20px;
  align-items: center;
  position: absolute;
  width: 17%;
  background-color: rgb(0, 175, 73);
  bottom: 0px;
}

.logOut:hover {
  cursor: pointer;
}

.activeItem {
  color: white;
  background-color: #455a64;
  text-decoration: none;
}

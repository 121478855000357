.containerMenu {
  width: 100%;
  border-radius: 5px;
  color: white;
  height: 100%;
}
.header_container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title_container {
  display: flex;
  align-items: center;
}
.menu_icon {
  padding: 10px;
  background-color: #444452;
  border-radius: 50px;
  margin-right: 20px;
}

.menu_text {
  display: flex;
  font-size: 25px;
  font-weight: 600;
  flex-direction: column;
  margin: 0px;
}

.menu_desc {
  font-size: 14px;
  font-weight: "normal";
  color: #808096;
  margin: 0px;
}

.menuItem_container {
  overflow-y: scroll;
  height: 600px;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background-color: #2c2c34;
}

::-webkit-scrollbar-thumb {
  background-color: #464652;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #696979;
  cursor: pointer;
}
